export default {
	name: 'Données pédagogiques',
	content: [
		{ 
			cells: [
				'PROMOTION',
				'',
				'COMPLÉTION',
				'',
				'',
			],
		},
		{
			table: 'groups',
			headers: [
				'ID Promotion',
				'Promotion',
				'Inscrits',
				'En cours',
				'Parcour terminé',
			],
			row: (group) => {
				return [
					'identifier',
					'name',
					{ value: (group.users.length || 0) },
					{ value: (group.usersData.countByProgress.isInProgress || 0) },
					{ value: (group.usersData.countByProgress.hasFinished || 0) },
				]
			},
		}
	]
}