<template>
	<p v-if="isLoading || !trackingData">
		Chargement...
	</p>
	<div v-else class="dashboard-user">
		<CategoryBlock title="Statistiques">
			<div class="dashboard-user-kpis">
				<KPIBlock title="Nombre de connexions" :number="trackingData.activityDashboardData.activeCount" />
				<KPIBlock title="Durée totale de connexion" :time="trackingData.activityDashboardData.activeTime.sum" />
				<KPIBlock title="Durée moyenne de connexion" :time="trackingData.activityDashboardData.activeTime.average" />

				<!-- <KPIBlock
				theme="wide-block"
				value-color="dashboard-green"
				title="Envoi du mail de relance"
				subtitle="J+1 session ratée"
				:date="trackingData.mailingDashboardData.remindersDate.noLogin" /> -->
			</div>

			<div class="dashboard-user-kpis">
				<KPIBlock
				theme="wide-block"
				value-color="dashboard-blue-dark"
				title="Mail d'invitation"
				:date="trackingData.mailingDashboardData.accessDate" />
				
				<KPIBlock
				theme="wide-block"
				value-color="dashboard-blue-dark"
				title="Dernière connexion"
				:date="trackingData.activityDashboardData.lastActiveDate" />

				<!-- <KPIBlock
				theme="wide-block"
				value-color="dashboard-green"
				title="Envoi du mail de relance"
				subtitle="J+1 formation non-terminée"
				:date="trackingData.mailingDashboardData.remindersDate.notFinishedAfterOneDay" /> -->
			</div>

			<div class="dashboard-user-kpis">
				<KPIBlock
				theme="wide-block"
				value-color="dashboard-blue-dark"
				title="Progression"
				:percentage="trackingData.progressionDashboardData.progress" />

				<KPIBlock
				theme="wide-block"
				value-color="dashboard-blue-dark"
				title="Date d'obtention des 100% de progression"
				:date="trackingData.progressionDashboardData.doneDate" />
				
				<!-- <KPIBlock
				theme="wide-block"
				value-color="dashboard-blue-dark"
				title="Complétion du niveau 2"
				:date="trackingData.progressionDashboardData.level2DoneDate" /> -->

				<!-- <KPIBlock
				theme="wide-block"
				value-color="dashboard-green"
				title="Envoi du mail de relance"
				subtitle="J+30 formation non-terminée"
				:date="trackingData.mailingDashboardData.remindersDate.notFinishedAfterOneMonth" /> -->
			</div>
		</CategoryBlock>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'

	import trackingReportTemplate from "@/constants/userTrackingReportTemplate"

	import CategoryBlock from "./block/Category"
	import KPIBlock from "./block/KPI"

	export default {
		name: 'UserDashboard',
		components: {
			CategoryBlock,
			KPIBlock
		},
		props: {
			id: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
			}
		},
		watch: {
			id: {
				async handler(id) {
					if (!id) {
						return
					}

					this.isLoading = true

					// Load tracking data
					this.trackingData = await trackingReport.loadData(this.$store, trackingReportTemplate, { id })

					this.isLoading = false
				},
				immediate: true
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-user {
		.dashboard-category {
			@apply w-full max-w-screen-lg mb-5 mx-auto;
		}

		&-kpis {
			@apply flex flex-wrap justify-between mb-8;

			&:last-child {
				@apply mb-0;
			}

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}

				&.wide-block {
					flex-grow: 1.5;
				}
			}
		}
	}
</style>