import extraUsersData from "./extraUsersData"
import extraCoursesData from "../common/extraCoursesData"
import extraGroupsData from "../common/extraGroupsData"

import usersDashboardData from "./usersDashboardData"
import userActivityDashboardData from "./userActivityDashboardData"
import userProgressionDashboardData from "./userProgressionDashboardData"
import userMailingDashboardData from "./userMailingDashboardData"

import usageSheet from "../common/usageSheet"
import progressionSheet from "../common/progressionSheet"
import usersSheet from "../common/usersSheet"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetGroupUsersTrackingData',
			payload: {
				roles: ['user'],
				logTypes: ['login', 'logout', 'mail', 'activity_completed', 'scenario_started', 'scenario_completed', 'course', 'module']
			},
			groupByUniq: 'id'
		},
		metaTypes: {
			module: 'Utils',
			action: 'initAll',
			state: 'metaTypeList',
			groupByUniq: 'slug'
		},
		courses: {
			module: 'Logs',
			action: 'GetCoursesTrackingData',
			groupByUniq: 'id'
		},
		groups: {
			module: 'Logs',
			action: 'GetGroupsTrackingData',
			groupByUniq: 'id',
			payload: {
				roles: ['user']
			},
		},
		scenarios: {
			module: 'Logs',
			action: 'GetScenarioTrackingData',
			groupByUniq: 'id',
			exportOnly: true,
		},
		activities: {
			module: 'Logs',
			action: 'GetActivitiesTrackingData',
			groupByUniq: 'id',
			exportOnly: true,
		},
	},
	extraData: {
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		courses: extraCoursesData,
		users: extraUsersData,
		confirmedUsers(data) {
			return data.users.filter((user) => {
				return user.cognito_confirmed
			})
		},
		groups: extraGroupsData,
		// Data used to construct the modules headers in usersSheet
		coursesData(data) {
			const usedCourseIds = data.groups.filter((group) => {
				return !!group.course
			}).map((group) => group.course.id)

			// Get the list of activity type potentially available in a module
			const potentialTypesByModuleOrder = {}

			data.courses.forEach((course) => {
				if (usedCourseIds.indexOf(course.id) < 0) {
					return
				}

				course.modules.forEach((module) => {
					const moduleData = course.modulesActivitiesData[module.id]

					if (!potentialTypesByModuleOrder[module.order]) {
						potentialTypesByModuleOrder[module.order] = {}
					}

					for (const typeSlug in moduleData) {
						if (moduleData[typeSlug]) {
							potentialTypesByModuleOrder[module.order][typeSlug] = true
						}
					}
				})
			})

			return {
				potentialTypesByModuleOrder,
			}
		},
		usersDashboardData,
		userActivityDashboardData,
		// userProgressionDashboardData,
		userMailingDashboardData
	},
	sheets: [
		usageSheet,
		progressionSheet,
		usersSheet,
	]
}