import XLSX from 'xlsx'

const storeDataCache = {}

function storeDataCacheKeyFor(actionName, payload) {
	return actionName + (payload ? '|' + JSON.stringify(payload) : '')
}

export default {
	async loadData(store, template, payload = null, forExport = false) {
		let data = {}

		// Load remote data from template specs
		const remoteDataKeys = Object.keys(template.remoteData || {})

		for (let i = 0; i < remoteDataKeys.length; i++) {
			const spec = template.remoteData[remoteDataKeys[i]]

			// Skip export only remote data if needed
			if (spec.exportOnly && !forExport) {
				continue
			}

			let result = null

			// Load data with a store action
			if (spec.action) {
				const actionName = (spec.module ? spec.module + '/' + spec.action : spec.action)
				
				let remoteDataPayload = ({...spec.payload} || {})

				if (payload) {
					if (!remoteDataPayload) {
						remoteDataPayload = payload
					} else {
						for (const key in payload) {
							remoteDataPayload[key] = payload[key]
						}
					}
				}

				// Handle store data cache
				const cacheKey = storeDataCacheKeyFor(actionName, (remoteDataPayload || null))

				result = (storeDataCache[cacheKey] ? JSON.parse(storeDataCache[cacheKey]) : null)

				if (!result) {
					result = await store.dispatch(actionName, (remoteDataPayload || null))

					// Get result from a store state if needed
					if (spec.state) {
						if (spec.module) {
							result = store.state[spec.module][spec.state]
						} else {
							result = store.state[spec.state]
						}
					}

					storeDataCache[cacheKey] = JSON.stringify(result)
				}
			} else if (spec.data) {
				result = spec.data
			}

			// Store data result
			data[remoteDataKeys[i]] = result

			// Create default group by if needed
			if (spec.groupBy) {
				this.getGroupByData(data, {
					list: remoteDataKeys[i],
					field: spec.groupBy
				})
			}

			if (spec.groupByUniq) {
				this.getGroupByData(data, {
					list: remoteDataKeys[i],
					field: spec.groupByUniq
				}, true)
			}
		}

		// Compute other data
		const extraDataKeys = Object.keys(template.extraData || {})

		extraDataKeys.forEach((key) => {
			if (!data[key]) {
				if (typeof template.extraData[key] == "function") {
					// Store new data
					data[key] = template.extraData[key](data)
				}
			} else {
				// Add extra fields to an existing list
				const extraFieldsKeys = Object.keys(template.extraData[key])

				if (data[key].forEach) {
					data[key].forEach((item) => {
						extraFieldsKeys.forEach((fieldKey) => {
							item[fieldKey] = template.extraData[key][fieldKey](item, data)
						})
					})
				} else {
					extraFieldsKeys.forEach((fieldKey) => {
						data[key][fieldKey] = template.extraData[key][fieldKey](data[key], data)
					})
				}
			}
		})

		return data
	},
	getGroupByData(data, { list, field }, uniq = false) {
		// Check for subfield
		const fields = field.split('.')
		let subfield = null

		if (fields.length > 1) {
			field = fields[0]
			subfield = fields[1]
		}

		// Group data by field value if needed
		const groupByName = list + 'By' + (field.charAt(0).toUpperCase() + field.slice(1))
		
		if (!data[groupByName]) {
			let reduce = null

			if (!uniq) {
				if (subfield == null) {
					reduce = (dict, item) => {
						if (!dict[item[field]])
							dict[item[field]] = []

						dict[item[field]].push(item)

						return dict
					}
				} else {
					reduce = (dict, item) => {
						if (!dict[item[field][subfield]])
							dict[item[field][subfield]] = []

						dict[item[field][subfield]].push(item)

						return dict
					}
				}
			} else {
				if (subfield == null) {
					reduce = (dict, item) => {
						dict[item[field]] = item

						return dict
					}
				} else {
					reduce = (dict, item) => {
						dict[item[field][subfield]] = item

						return dict
					}
				}
			}

			data[groupByName] = data[list].reduce(reduce, {})
		}

		return data[groupByName]
	},
	getSheetContent(data, sheetTemplate, sheetCellsByType) {
		// Create sheet content array (only two column for now)
		let content = []

		// Add content from template specs
		for (let i = 0; i < sheetTemplate.content.length; i++) {
			const spec = sheetTemplate.content[i]

			if (spec.cells) {
				let cells = []

				let list = (typeof spec.cells == 'function' ? spec.cells(data) : spec.cells)

				list.forEach((cell, index) => {
					// Simple text value
					if (typeof cell === 'string') {
						cells.push(cell)
						return
					}

					cells.push(cell.text || (cell.value ? cell.value(data) : ''))

					// Handle cell type
					if (cell.type) {
						const cellRef = XLSX.utils.encode_cell({ c: index, r: content.length })

						sheetCellsByType[cell.type].push(cellRef)
					}
				})

				content.push(cells)
			} else if (spec.table) {
				// Add table headers
				if (typeof spec.headers == 'function') {
					content.push(spec.headers(data[spec.table], data))
				} else if (spec.headers && spec.headers.length > 0) {
					content.push(spec.headers)
				}

				// Get list
				let list = (typeof spec.table == 'function' ? spec.table(data) : data[spec.table])
				
				if (!list)
					continue

				// Add each row data
				list.forEach((item) => {
					const parseRow = (field, index) => {
						if (typeof field === 'string')
							return item[field]

						// Handle field type
						if (field.type) {
							const cellRef = XLSX.utils.encode_cell({ c: index, r: content.length })

							sheetCellsByType[field.type].push(cellRef)
						}

						return field.value
					}

					// Get row(s) cells
					let rows = (
						spec.rows
						? spec.rows(item, data)
						: [spec.row(item, data)]
					)

					// Parse rows cells
					rows = rows.map((row) => {
						return row.map(parseRow)
					})

					rows.forEach((row) => {
						content.push(row)
					})
				})
			} else if (spec.groupBy) {
				// Get data list grouped by field value
				const groupByData = this.getGroupByData(data, spec.groupBy)

				// Create a line for each group
				const groupNames = (spec.groupNames || Object.keys(groupByData))

				groupNames.forEach((name) => {
					const text = spec.text(name, groupByData[name], data)
					const value = spec.value(groupByData[name] || [], data)

					// Handle cell type
					if (spec.type) {
						const cellRef = XLSX.utils.encode_cell({ c: 1, r: content.length })

						sheetCellsByType[spec.type].push(cellRef)
					}

					content.push([text, value])
				})
			} else {
				let line = []

				if (spec.text != undefined) {
					line.push(spec.text || '')
				}

				if (spec.value) {
					line.push(spec.value(data))
				} else {
					line.push('')
				}

				// Handle cell type
				if (spec.type) {
					const cellRef = XLSX.utils.encode_cell({ c: 1, r: content.length })

					sheetCellsByType[spec.type].push(cellRef)
				}

				content.push(line)
			}
		}

		return content
	},
	getSheet(sheetTemplate, data, template) {
		// Init sheet cells type dictionary
		let sheetCellsByType = Object.keys(template.types).reduce((dict, type) => {
			dict[type] = []

			return dict
		}, {})

		// Get content
		const content = this.getSheetContent(data, sheetTemplate, sheetCellsByType)

		// Create sheet from content array
		const sheet = XLSX.utils.aoa_to_sheet(content)

		// Add custom formats to specified sheet cells
		const sheetTypes = Object.keys(sheetCellsByType)

		sheetTypes.forEach((typeName) => {
			const type = template.types[typeName]

			if (typeof type === 'string') {
				sheetCellsByType[typeName].forEach((cellRef) => {
					if (!sheet[cellRef]) {
						return
					}

					sheet[cellRef].z = type
				})
			} else if (type.transform) {
				sheetCellsByType[typeName].forEach((cellRef) => {
					if (!sheet[cellRef]) {
						return
					}

					const value = type.transform(sheet[cellRef].v)

					if (value !== false) {
						sheet[cellRef].v = value
						sheet[cellRef].z = type.format
					} else {
						sheet[cellRef].v = '-'
						sheet[cellRef].t = 's'
					}
				})
			}
		})

		if (window.DEBUG) {
			/* eslint-disable-next-line */
			console.info(sheetTemplate.name)
			/* eslint-disable-next-line */
			console.table(content)
			return
		}

		return sheet
	},
	addData(file, data, template) {
		if (!template.sheets)
			return

		// Add data for each sheet
		template.sheets.forEach((sheetTemplate) => {
			if (!sheetTemplate.name)
				return

			// Handle dynamic sheets from a list
			if (sheetTemplate.list) {
				const list = data[sheetTemplate.list]

				if (!list)
					return

				for (var i = 0; i < list.length; i++) {
					data[sheetTemplate.itemName] = list[i]

					const sheet = this.getSheet(sheetTemplate, data, template)

					// Add sheet to file
					XLSX.utils.book_append_sheet(file, sheet, sheetTemplate.name(list[i]))
				}
			} else {
				// Handle simple sheet
				const sheet = this.getSheet(sheetTemplate, data, template)

				// Add sheet to file
				XLSX.utils.book_append_sheet(file, sheet, sheetTemplate.name)
			}
		})
	},
	hasLoadedExportOnlyRemoteData(loadedData, template) {
		const remoteDataKeys = Object.keys(template.remoteData || {})
		const exportOnlyKeys = remoteDataKeys.reduce((keys, remoteDataKey) => {
			if (template.remoteData[remoteDataKey].exportOnly) {
				keys.push(remoteDataKey)
			}
			return keys
		}, [])

		// Check if all export only keys are present in the loadedData
		for (var i = 0; i < exportOnlyKeys.length; i++) {
			if (loadedData[exportOnlyKeys[i]] === undefined) {
				return false
			}
		}

		return true
	},
	async export(fileNamePrefix, store, template, payload = null, loadedData = null) {
		// Create new file
		const file = XLSX.utils.book_new()

		// Load tracking data for template if needed
		if (!loadedData || !this.hasLoadedExportOnlyRemoteData(loadedData, template)) {
			loadedData = await this.loadData(store, template, payload, true)
		}

		const data = loadedData

		// Add formated data to file
		this.addData(file, data, template)

		if (window.DEBUG) {
			return
		}

		// Create file name formated like: YearMonthDay_Rapport.xlsx
		const today = new Date()
		const fileName = `${(fileNamePrefix ? fileNamePrefix + '_' : '')}${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}_Rapport.xlsx`
		
		// Write file to download output
		XLSX.writeFile(file, fileName)
	},
}