import utils from "@/utils/trackingReportTemplate"

const now = Date.now()
const oneDay = (1 * 60 * 60 * 1000 * 24)
const oneMonth = (oneDay * 30)
const threeMonth = (oneMonth * 3)
const oneYear = (oneDay * 365)

export default {
	scenarioDoneById(user) {
		// Get scenario done by id
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'scenario_completed') {
				dict[log.data_key] = true
			}

			return dict
		}, {})
	},
	progressionPerGroup(user, data) {
		const defaultCourse = data.courses[0]

		return user.groups.reduce((progressionPerGroup, group) => {
			// Get course from group or take the default one
			const course = (data.groupsById[group.id]?.course || defaultCourse)

			if (!course) {
				progressionPerGroup[group.id] = 0
				return progressionPerGroup
			}

			let scenarioDone = 0
			let scenarioAvailable = 0

			// Count scenario done and available in this group
			course.modules.forEach((module) => {
				// Count scenario done in this module
				module.scenarios.forEach((scenario) => {
					if (user.scenarioDoneById[scenario.id]) {
						scenarioDone += 1
					}
				})

				// Count scenario available in this module
				scenarioAvailable += module.scenarios.length

				// Count scenario available and done in the module sequences
				module.sequences.forEach((sequence) => {
					// Count scenario done in this sequence
					sequence.scenarios.forEach((scenario) => {
						if (user.scenarioDoneById[scenario.id]) {
							scenarioDone += 1
						}
					})

					// Count scenario available in this sequence
					scenarioAvailable += sequence.scenarios.length
				})
			})

			// Set progression percentage for this group
			if (scenarioAvailable <= 0) {
				progressionPerGroup[group.id] = 0
			} else {
				progressionPerGroup[group.id] = (scenarioDone / scenarioAvailable)
			}

			return progressionPerGroup
		}, {})
	},
	progression(user) {
		// Make an average of user progression per group
		return utils.groupAverage(user.progressionPerGroup, (progression) => progression)
	},
	job(user, data) {
		const metaType = data.metaTypesBySlug['job']

		// Check if the user has a job defined
		if (metaType && user.metas) {
			for (var i = 0; i < user.metas.length; i++) {
				if (user.metas[i].meta_type_id == metaType.id) {
					return user.metas[i].value
				}
			}
		}

		// If no value found return the default one
		return 'other'
	},
	state(user) {
		// Check if the user group is not disabled and still active (start/end date)
		const hasGroup = (user.groups.length > 0)
		let isGroupActive = (hasGroup && !user.groups[0].data.disabled)

		if (isGroupActive) {
			const startDate = new Date(user.groups[0].data.start_date)
			const endDate = new Date(user.groups[0].data.end_date)

			isGroupActive = (now > startDate.getTime() && now < endDate.getTime())
		}

		if (user.disabled || (hasGroup && !isGroupActive)) {
			return 'disabled'
		} else if (hasGroup && isGroupActive && (!user.cognito_id || !user.cognito_confirmed)) {
			return 'inactive'
		} else if (user.cognito_id) {
			if (user.cognito_confirmed) {
				return 'active'
			}

			return 'pending'
		}

		return 'exist'
	},
	progressionData(user, data) {
		// Init computed data
		let computedProgressionData = {
			hasStarted: false,
			progress: 0,
			doneTimestamp: null,
		}

		// Get scenario done by user and the date of the first time it was done
		const userScenarioFirstDoneDateById = {}

		user.logs.forEach((log) => {
			const logTime = (new Date(log.created_at)).getTime()

			if (!computedProgressionData.hasStarted && log.type.slug == 'activity_completed') {
				computedProgressionData.hasStarted = true
			} else if (log.type.slug == 'scenario_completed' && !userScenarioFirstDoneDateById[log.data_key]) {
				computedProgressionData.hasStarted = true
				
				userScenarioFirstDoneDateById[log.data_key] = logTime
			}
		})
		
		// Count scenario done and available in the user courses
		const userCourses = user.groups.reduce((list, groupData) => {
			if (data.coursesById[groupData.data.course_id]) {
				list.push(data.coursesById[groupData.data.course_id])
			}
			return list
		}, [])

		const userCoursesProgress = userCourses.map((course) => {
			let scenarioDone = 0
			let scenarioAvailable = 0
			let lastScenarioTimestamp = null

			course.modules.forEach((module) => {
				// Count scenario done in this module
				module.scenarios.forEach((scenario) => {
					if (userScenarioFirstDoneDateById[scenario.id]) {
						scenarioDone += 1
						lastScenarioTimestamp = userScenarioFirstDoneDateById[scenario.id]
					}
				})

				// Count scenario available in this module
				scenarioAvailable += module.scenarios.length

				// Count scenario available and done in the module sequences
				module.sequences.forEach((sequence) => {
					// Count scenario done in this sequence
					sequence.scenarios.forEach((scenario) => {
						if (userScenarioFirstDoneDateById[scenario.id]) {
							scenarioDone += 1
							lastScenarioTimestamp = userScenarioFirstDoneDateById[scenario.id]
						}
					})

					// Count scenario available in this sequence
					scenarioAvailable += sequence.scenarios.length
				})
			})

			return {
				progress: (scenarioDone / (scenarioAvailable || 1)),
				lastScenarioTimestamp,
			}
		})


		if (userCoursesProgress.length > 0) {
			const progressSum = userCoursesProgress.reduce((sum, courseProgress) => {
				sum += courseProgress.progress
				return sum
			}, 0)

			computedProgressionData.progress = (progressSum / userCoursesProgress.length)
		}

		if (computedProgressionData.progress >= 1) {
			computedProgressionData.doneTimestamp = userCoursesProgress.reduce((lastTimestamp, courseProgress) => {
				if (!lastTimestamp || courseProgress.lastScenarioTimestamp > lastTimestamp) {
					lastTimestamp = courseProgress.lastScenarioTimestamp
				}
				
				return lastTimestamp
			}, null)
		}

		return computedProgressionData
	},
	logsByType(user) {
		// Create a map of log type slug => log from the user logs
		return user.logs.reduce((dict, log) => {
			if (!dict[log.type.slug]) {
				dict[log.type.slug] = []
			}

			dict[log.type.slug].push(log)

			return dict
		}, {})
	},
	mailingLogs(user) {
		// Create a map of mail type => log from the user mailer logs
		return (user.logsByType.mail || []).reduce((dict, log) => {
			if (log.data.type) {
				dict[log.data.type] = log
			}

			return dict
		}, {})
	},
	// progressionPerChapter(user, data) {
	// 	// Create a dictionary of chapter progression by their id
	// 	return data.chapters.reduce((dict, chapter) => {
	// 		// Get order of the last visited zone in this chapter
	// 		const lastZoneOrder = user.planche_zone_visiteds.reduce((lastOrder, log) => {
	// 			const order = chapter.zoneOrders[log.planche_zone_id]

	// 			if (order > -1 && lastOrder < order) {
	// 				lastOrder = order
	// 			}

	// 			return lastOrder
	// 		}, -1)

	// 		// Get progression percentage
	// 		dict[chapter.id] = ((lastZoneOrder + 1) / (chapter.zoneOrders.count || 1))

	// 		return dict
	// 	}, {})
	// },
	// progression(user) {
	// 	// Make an average of user progression per chapter
	// 	return utils.groupAverage(user.progressionPerChapter, (progression) => progression)
	// },
	createdWeekId(user) {
		// Generate a uniq week id for the user subscription date
		return utils.weekId(new Date(user.created_at))
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			monthIds: {},
			yearIds: {},
			lastSessionTimestamp: 0
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}
			
			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push(logTime)

			// Register all log time who should always trigger a new session
			if (['login', 'logout'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const fiveMinutes = (5 * 60 * 1000)

		let lastLogTime = 0

		times.forEach((logTime) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if two logs are too far apart or if we need to create a new session
			if (deltaTime > oneHour || sessionStops[logTime]) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes

				// Register session week, month and year as active
				const logDate = new Date(logTime)
				
				const weekId = utils.weekId(logDate)
				const monthId = utils.monthId(logDate)
				const yearId = utils.yearId(logDate)

				if (computedTimeData.weekIds[weekId] === undefined) {
					computedTimeData.weekIds[weekId] = true
				}
				if (computedTimeData.monthIds[monthId] === undefined) {
					computedTimeData.monthIds[monthId] = true
				}
				if (computedTimeData.yearIds[yearId] === undefined) {
					computedTimeData.yearIds[yearId] = true
				}
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	successRatePerGame(user) {
		// Get best success rate for each game played
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'game' && log.data != null) {
				const successRate = (dict[log.data_key] || -1)

				// Only keep best success rate from all attempt
				if (log.data > successRate) {
					dict[log.data_key] = log.data
				}
			}

			return dict
		}, {})
	},
	successRate(user) {
		if (Object.keys(user.successRatePerGame).length <= 0)
			return -1

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerGame, (successRate) => successRate)
	},
	// successRatePerChapter(user, data) {
	// 	// Create a dictionary of average games success rate by chapter id
	// 	return data.chapters.reduce((dict, chapter) => {
	// 		const results = chapter.games.reduce((results, gameId) => {
	// 			const successRate = user.successRatePerGame[gameId]

	// 			if (successRate != undefined) {
	// 				results.sum += successRate
	// 				results.count += 1
	// 			}

	// 			return results
	// 		}, {
	// 			sum: 0,
	// 			count: 0
	// 		})

	// 		// Compute average success rate
	// 		if (results.count > 0) {
	// 			dict[chapter.id] = (results.sum / results.count)
	// 		}

	// 		return dict
	// 	}, {})
	// }
	modulesActivitiesData(user, data) {
		if (!data.scenarios || !data.activities) {
			return {}
		}

		// Get user best score for each activity
		const scoreByActivityId = (user.logsByType['activity_completed'] || []).reduce((scoreById, log) => {
			const score = log.data.score || 0

			if (!scoreById[log.data_key]) {
				scoreById[log.data_key] = score
			} else if (scoreById[log.data_key] < score) {
				scoreById[log.data_key] = score
			}

			return scoreById
		}, {})

		// Handle legacy user without the right logs for activities
		const firstLoginDate = (user.logsByType.login && user.logsByType.login[0] ? new Date(user.logsByType.login[0].created_at) : null)

		// Legacy user who has logged in before the 13 december 2023 at 9:35 AM
		if (firstLoginDate && firstLoginDate < 1702456507000) {
			// Compute activity score with scenario score (the only score available for them)
			(user.logsByType['scenario_completed'] || []).forEach((log) => {
				const scenario = data.scenariosById[log.data_key]
				const scenarioScore = log.data.score || 0

				if (!scenario || !scenarioScore) {
					return
				}

				// Approximate the number of succesfull activities in this scenario
				const succesfullActivityCount = Math.round(scenarioScore * scenario.nodes.length)

				// Add a perfect score for the "right" number of activities
				for (var i = 0; i < succesfullActivityCount; i++) {
					const node = scenario.nodes[i]

					if (!node) {
						continue
					}

					const activity = data.activitiesById[node.node_content]

					if (!activity) {
						continue
					}

					if (!scoreByActivityId[activity.id]) {
						scoreByActivityId[activity.id] = 1
					}
				}
			})
		}

		const countByModuleId = {}

		// Get succesfull activities count by type for each group modules
		user.groups.forEach((groupData) => {
			const group = data.groupsById[groupData.id]

			// Add result to the associative dictionnary of module ID => successfullActivitiesCountByType
			group?.course.modules.forEach((module) => {
				const successfullActivitiesCountByType = {}

				// Add counts from module scenarios
				module.scenarios.forEach((scenarioData) => {
					const scenario = data.scenariosById[scenarioData.id]

					scenario.nodes.forEach((node) => {
						const activity = data.activitiesById[node.node_content]

						if (!activity) {
							return
						}

						const score = scoreByActivityId[activity.id] || 0

						if (score >= 1) {
							const typeSlug = activity.type.slug

							successfullActivitiesCountByType[typeSlug] = (successfullActivitiesCountByType[typeSlug] || 0) + 1
						}
					})
				})

				// Add counts from sequence scenarios
				module.sequences.forEach((sequence) => {
					sequence.scenarios.forEach((scenarioData) => {
						const scenario = data.scenariosById[scenarioData.id]

						scenario.nodes.forEach((node) => {
							const activity = data.activitiesById[node.node_content]

							if (!activity) {
								return
							}

							const score = scoreByActivityId[activity.id] || 0

							if (score >= 1) {
								const typeSlug = activity.type.slug

								successfullActivitiesCountByType[typeSlug] = (successfullActivitiesCountByType[typeSlug] || 0) + 1
							}
						})
					})
				})

				countByModuleId[module.id] = successfullActivitiesCountByType
			})
		})

		return countByModuleId
	},
}