const activityWithScoreTypeSlugs = [
	'quiz_simple',
	'true_false',
	'gap_fill',
	'reorder',
	'dragdrop',
	'dragdrop_picture',
]

export default {
	moduleById(course) {
		return course.modules.reduce((dict, module) => {
			dict[module.id] = module
			return dict
		}, {})
	},
	modulesActivitiesData(course, data) {
		if (!data.scenarios || !data.activities) {
			return {}
		}

		// Get activity types count by scenario
		const activityTypesCountByScenarioId = data.scenarios.reduce((dict, scenario) => {
			dict[scenario.id] = scenario.nodes.reduce((typesCount, node) => {
				// Get node activity type
				const activity = data.activitiesById[node.node_content]

				if (!activity) {
					return typesCount
				}

				// Add/set count
				typesCount[activity.type.slug] = (typesCount[activity.type.slug] || 0) + 1

				return typesCount
			}, {})

			return dict
		}, {})

		// Get activity types count by module
		return course.modules.reduce((dict, module) => {
			const moduleCountsByType = {}

			// Add counts from module scenarios
			module.scenarios.forEach((scenarioData) => {
				const typesCount = activityTypesCountByScenarioId[scenarioData.id]

				for (const typeSlug in typesCount) {
					if (activityWithScoreTypeSlugs.indexOf(typeSlug) < 0) {
						continue
					}

					const count = typesCount[typeSlug]

					moduleCountsByType[typeSlug] = (moduleCountsByType[typeSlug] || 0) + count
				}
			})

			// Add counts from sequence scenarios
			module.sequences.forEach((sequence) => {
				sequence.scenarios.forEach((scenarioData) => {
					const typesCount = activityTypesCountByScenarioId[scenarioData.id]

					for (const typeSlug in typesCount) {
						if (activityWithScoreTypeSlugs.indexOf(typeSlug) < 0) {
							continue
						}

						const count = typesCount[typeSlug]

						moduleCountsByType[typeSlug] = (moduleCountsByType[typeSlug] || 0) + count
					}
				})
			})

			dict[module.id] = moduleCountsByType

			return dict
		}, {})
	},
}