var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"inline-block w-1/3 mb-4"},[(!_vm.isReadOnly)?_c('label',[_vm._v("Ajouter un utilisateur")]):_vm._e(),(!_vm.isReadOnly)?_c('v-select',{attrs:{"options":_vm.internsList,"filterable":true,"label":"name","placeholder":"Rechercher un utilisateur..."},on:{"input":function($event){return _vm.onUserSelect($event)}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Aucun utilisateur disponible. ")]},proxy:true}],null,false,3542634599),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}}):_vm._e()],1),_c('div',{staticClass:"inline-block w-2/3 text-right px-4"},[(!_vm.isReadOnly)?_c('button',{staticClass:"twn-button block whitespace-no-wrap overflow-hidden text-xs mb-2 ml-auto",attrs:{"type":"button"},on:{"click":_vm.onImportUserClick}},[_vm._v("Importer des utilisateurs")]):_vm._e(),_c('span',[_vm._v(_vm._s(this.promotion.users.length))]),_c('span',[_vm._v(" utilisateurs")])]),_c('div',{staticClass:"border-line-left border rounded p-4"},[_c('table',{staticClass:"group-interns w-full"},[_c('tr',[_c('th',{staticClass:"select-none cursor-pointer",on:{"click":function($event){return _vm.onHeaderClick('first_name')}}},[_vm._v(" Prénom "),_c('span',{staticClass:"arrow-sort-up",class:{
              'opacity-100': _vm.sortByField == 'first_name' && _vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")]),_c('span',{staticClass:"arrow-sort-down",class:{
              'opacity-100': _vm.sortByField == 'first_name' && !_vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")])]),_c('th',{staticClass:"select-none cursor-pointer",on:{"click":function($event){return _vm.onHeaderClick('name')}}},[_vm._v(" Nom "),_c('span',{staticClass:"arrow-sort-up",class:{
              'opacity-100': _vm.sortByField == 'name' && _vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")]),_c('span',{staticClass:"arrow-sort-down",class:{
              'opacity-100': _vm.sortByField == 'name' && !_vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")])]),_c('th',{staticClass:"select-none cursor-pointer",on:{"click":function($event){return _vm.onHeaderClick('email')}}},[_vm._v(" Email "),_c('span',{staticClass:"arrow-sort-up",class:{
              'opacity-100': _vm.sortByField == 'email' && _vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")]),_c('span',{staticClass:"arrow-sort-down",class:{
              'opacity-100': _vm.sortByField == 'email' && !_vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")])]),_c('th',{staticClass:"select-none cursor-pointer",on:{"click":function($event){return _vm.onHeaderClick('state')}}},[_vm._v(" Statut "),_c('span',{staticClass:"arrow-sort-up",class:{
              'opacity-100': _vm.sortByField == 'state' && _vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")]),_c('span',{staticClass:"arrow-sort-down",class:{
              'opacity-100': _vm.sortByField == 'state' && !_vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")])]),_c('th',{staticClass:"select-none cursor-pointer",on:{"click":function($event){return _vm.onHeaderClick('progressNum')}}},[_vm._v(" Progression "),_c('span',{staticClass:"arrow-sort-up",class:{
              'opacity-100': _vm.sortByField == 'progressNum' && _vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")]),_c('span',{staticClass:"arrow-sort-down",class:{
              'opacity-100': _vm.sortByField == 'progressNum' && !_vm.sortByAscOrder,
            }},[_vm._v(" ▴ ")])]),(!_vm.isReadOnly)?_c('th'):_vm._e()]),_vm._l((_vm.promotionUserList),function(intern){return _c('tr',{key:intern.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.onEditInternClick(intern)}}},[(_vm.interns[intern.id])?[_c('td',[_vm._v(_vm._s(_vm.interns[intern.id].first_name))]),_c('td',[_vm._v(_vm._s(_vm.interns[intern.id].name))]),_c('td',[_vm._v(_vm._s(_vm.interns[intern.id].email))]),_c('td',[_vm._v(_vm._s(_vm.interns[intern.id].state))]),_c('td',[_vm._v(_vm._s(_vm.interns[intern.id].progress))]),(!_vm.isReadOnly)?_c('td',{staticClass:"flex justify-center text-xl font-bold items-center cursor-pointer col-span-1",on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveInternClick(intern)}}},[_c('div',{staticClass:"border border-gray-light rounded-full text-center w-8 h-8"},[_vm._v("-")])]):_vm._e()]:_vm._e()],2)})],2)]),_c('UserImportModal',{attrs:{"defaultGroup":_vm.promotion.id},model:{value:(_vm.isImportModalOpen),callback:function ($$v) {_vm.isImportModalOpen=$$v},expression:"isImportModalOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }