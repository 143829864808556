import utils from "@/utils/trackingReportTemplate"

export default {
	usersData(group, data) {
		// Get users data from their id
		const users = group.users.map((user) => {
			return data.usersById[user.id]
		})

		// Count user by state
		const countbyState = utils.groupMap(utils.groupBy(users, 'state'), (users) => {
			return users.length
		})

		const activeTime = utils.sumField(users, 'activeTimeData', 'sum')

		// Get user count by their progression steps
		const progressStepFilters = {
			isInProgress: (user) => (user.state != 'disabled' && user.progressionData.hasStarted && !user.progressionData.doneTimestamp),
			hasFinished: (user) => (user.state != 'disabled' && user.progressionData.hasStarted && user.progressionData.doneTimestamp),
		}
		const countByProgress = utils.countMatchByGroup(users, progressStepFilters)

		// Get user mailing rate by type
		const hasReceiveMail = (user, mailType) => {
			return !!user.mailingLogs[mailType]
		}
		const mailingFilters = {
			noLogin: (user) => (hasReceiveMail(user, 'reminder-no-login') || hasReceiveMail(user, 'reminder-no-login-active')),
			notFinishedAfterOneDay: (user) => (hasReceiveMail(user, 'reminder-not-finished-day')),
			notFinishedAfterOneMonth: (user) => (hasReceiveMail(user, 'reminder-not-finished-month'))
		}
		const rateByMailType = (users) => {
			const countByMailType = utils.countMatchByGroup(users, mailingFilters)

			return utils.groupMap(countByMailType, (count) => {
				if (users.length <= 0) {
					return 0.0
				}

				return (count / users.length)
			})
		}

		return {
			countbyState,
			activeTime,
			countByProgress,
			rateByMailType: rateByMailType(users),
		}
	},
	modulesActivitiesData(group, data) {
		if (!data.scenarios || !data.activities) {
			return {}
		}

		// Get activity types count by scenario
		const activityTypesCountByScenarioId = data.scenarios.reduce((dict, scenario) => {
			dict[scenario.id] = scenario.nodes.reduce((typesCount, node) => {
				// Get node activity type
				const activity = data.activitiesById[node.node_content]

				if (!activity) {
					return typesCount
				}

				// Add/set count
				typesCount[activity.type.slug] = (typesCount[activity.type.slug] || 0) + 1

				return typesCount
			}, {})

			return dict
		}, {})

		// Get activity types count by module
		return group.course.modules.reduce((dict, module) => {
			const moduleCountsByType = {}

			// Add counts from module scenarios
			module.scenarios.forEach((scenarioData) => {
				const typesCount = activityTypesCountByScenarioId[scenarioData.id]

				for (const typeSlug in typesCount) {
					const count = typesCount[typeSlug]

					moduleCountsByType[typeSlug] = (moduleCountsByType[typeSlug] || 0) + count
				}
			})

			// Add counts from sequence scenarios
			module.sequences.forEach((sequence) => {
				sequence.scenarios.forEach((scenarioData) => {
					const typesCount = activityTypesCountByScenarioId[scenarioData.id]

					for (const typeSlug in typesCount) {
						const count = typesCount[typeSlug]

						moduleCountsByType[typeSlug] = (moduleCountsByType[typeSlug] || 0) + count
					}
				})
			})

			dict[module.id] = moduleCountsByType

			return dict
		}, {})
	},
}