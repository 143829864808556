<template>
  <div>
    <List
      v-if="!isLoading"
      listing="customer"
      :fields="fields"
      search-text="Rechercher un client"
      :pageSize="pageSize"
      edit-route-name="customer-edit"
    >
      <template v-slot:buttons>
        <div v-if="!isReadOnly" class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddCustomerClick"
          >
            Ajouter un nouveau client
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
  import List from "@/components/List";
  import { mapActions, mapState } from "vuex";

  export default {
    components: {
      List,
    },
    props: {
      pageSize:{
        type: Number,
        default: 30,
        required: false
      }
    },
    data: () => ({
      isLoading: false,
      defaultCourse: null,
    }),
    computed: {
      ...mapState('Auth', [ 'userInfo' ]),
      isSuperAdmin() {
        return (this.userInfo && this.userInfo.role == 'superadmin')
      },
      isUserAdmin() {
        return (this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'customer_manager'))
      },
      isReadOnly() {
        return !this.isSuperAdmin
      },
      availableScenarioCount() {
        if (!this.defaultCourse) {
          return null
        }

        let scenarioAvailable = 0

        // Count scenario available for default course
        this.defaultCourse.modules.forEach((module) => {
          // Count scenario available in this module
          scenarioAvailable += module.scenarios.length

          // Count scenario available and done in the module sequences
          module.sequences.forEach((sequence) => {
            // Count scenario available in this sequence
            scenarioAvailable += sequence.scenarios.length
          })
        })

        return scenarioAvailable
      },
      fields() {
        if (this.availableScenarioCount === null) {
          return null
        }

        return [
          {
            key: "identifier",
            label: "Identifiant",
            sortable: true,
          },
          {
            key: "name",
            label: "Nom",
            sortable: true,
          },
          {
            key: 'managers',
            label: 'Responsables',
            sortable: true,
            db: `managers {
              user {
                first_name
                last_name
              }
            }`,
            formatter: (managers) => {
              if (!managers)
                return 'Aucun'

              return managers.map((manager) => [manager.user.first_name, manager.user.last_name].filter(str => str || false).join(' ') || '~ Utilisateur supprimé ~').join(', ')
            }
          },
          {
            key: 'groups_aggregate',
            label: 'Promotions',
            sortable: true,
            db: `groups_aggregate {
              aggregate {
                count
              }
            }`,
            nested: 'count'
          },
          {
            key: 'groups.users_aggregate',
            label: 'Stagiaires',
            sortable: true,
            db: `groups {
              users_aggregate {
                aggregate {
                  count
                }
              }
            }`,
            formatter: (_value, _key, item) => {
              if (!item.groups)
                return 0

              return item.groups.reduce((count, group) => (count + group.users_aggregate.aggregate.count), 0)
            }
          },
          {
            label: 'Actifs',
            sortable: true,
            key: 'activeGroups',
            db: `activeGroups: groups {
              users_aggregate(where: {user: {cognito_confirmed: {_eq: true}}}) {
                aggregate {
                  count
                }
              }
            }`,
            formatter: (_value, _key, item) => {
              if (!item.activeGroups)
                return 0

              return item.activeGroups.reduce((count, group) => (count + group.users_aggregate.aggregate.count), 0)
            }
          },
          {
            label: 'Terminés',
            sortable: true,
            key: 'completedGroups',
            db: `completedGroups: groups {
              users_aggregate(where: {user: {user_logs_aggregate: {count: {predicate: {_gte: ${this.availableScenarioCount}}, arguments: data_key, distinct: true, filter: {type: {slug: {_eq: "scenario_completed"}}}}}}}) {
                aggregate {
                  count
                }
              }
            }`,
            formatter: (_value, _key, item) => {
              if (!item.completedGroups)
                return 0

              return item.completedGroups.reduce((count, group) => (count + group.users_aggregate.aggregate.count), 0)
            }
          },
          {
            key: 'quota',
            label: 'Quota',
            sortable: true,
          },
        ]
      }
    },
    async mounted() {
      this.isLoading = true

      this.defaultCourse = await this.$store.dispatch('Course/GetDefaultCourse')

      this.isLoading = false
    },
    methods: {
      ...mapActions("EditItem", ["clearCurrentID"]),
      onAddCustomerClick() {
        this.clearCurrentID();
        this.$router.push({ name: "customer-create" });
      },
    },
  };
</script>