<template>
  <div>
      <List
        listing="course"
        orderBy="title: asc"
        :previousPage="previousPage"
        :fields="fields"
        :filters="filters"
        modify-button-text="Éditer le parcours"
        :with-delete-button="false"
        searchText="Rechercher un parcours"
      >
        <template v-slot:buttons>
            <div class="md:flex flex-row justify-around hidden md:w-1/3">
                <button type="button" class="twn-button danger whitespace-no-wrap overflow-hidden text-xs mr-2" @click="addCourse">Ajouter un parcours</button>
            </div>
        </template>
      </List>
  </div>
</template>

<script>
import List from '@/components/List'

export default {
    components: {
        List,
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    data(){
        return{
            fields: [
                {
                    key: "identifier",
                    label: "Identifiant",
                    sortable: true,
                },
                {
                    key: 'title',
                    label: 'Nom',
                    sortable: true,
                },
            ],
            filters: []
        }
    },
    methods:{
        addCourse(){
            this.$router.push({name: 'course-create'})
        }
    }
}
</script>

<style>

</style>