export default {
	name: 'Données de connexion',
	content: [
		{ 
			cells: [ 'PROMOTION', '', 'UTILISATEURS', '', '', '', '', '' ],
		},
		{
			table: 'groups',
			headers: [
				'ID Promotion',
				'Promotion',
				'Inscrits',
				'En attente',
				'Désactivés',
				'Actifs',
				'Inactifs',
				'Durée totale de connexion'
			],
			row: (group) => {
				return [
					'identifier',
					'name',
					{ value: (group.users.length || 0) },
					{ value: (group.usersData.countbyState.pending || 0) },
					{ value: (group.usersData.countbyState.disabled || 0) },
					{ value: (group.usersData.countbyState.active || 0) },
					{ value: (group.usersData.countbyState.inactive || 0) },
					{ value: (group.usersData.activeTime || 0), type: 'duration' }
				]
			},
		}
	]
}