<template>
  <div class="mt-4">
    <div class="inline-block w-1/3 mb-4">
      <label v-if="!isReadOnly">Ajouter un utilisateur</label>
      <v-select
      v-if="!isReadOnly"
      :options="internsList"
      :filterable="true"
      label="name"
      placeholder="Rechercher un utilisateur..."
      v-model="selectedUser"
      @input="onUserSelect($event)"
      >
        <template #no-options>
          Aucun utilisateur disponible.
        </template>
      </v-select>
    </div>

    <div class="inline-block w-2/3 text-right px-4">
      <button v-if="!isReadOnly" @click="onImportUserClick" type="button" class="twn-button block whitespace-no-wrap overflow-hidden text-xs mb-2 ml-auto">Importer des utilisateurs</button>
      <span>{{ this.promotion.users.length }}</span>
      <span> utilisateurs</span>
    </div>

    <div class="border-line-left border rounded p-4">
      <table class="group-interns w-full">
        <tr>
          <th
            class="select-none cursor-pointer"
            @click="onHeaderClick('first_name')"
          >
            Prénom
            <span
              class="arrow-sort-up"
              :class="{
                'opacity-100': sortByField == 'first_name' && sortByAscOrder,
              }"
            >
              ▴
            </span>
            <span
              class="arrow-sort-down"
              :class="{
                'opacity-100': sortByField == 'first_name' && !sortByAscOrder,
              }"
            >
              ▴
            </span>
          </th>
          <th
            class="select-none cursor-pointer"
            @click="onHeaderClick('name')"
          >
            Nom
            <span
              class="arrow-sort-up"
              :class="{
                'opacity-100': sortByField == 'name' && sortByAscOrder,
              }"
            >
              ▴
            </span>
            <span
              class="arrow-sort-down"
              :class="{
                'opacity-100': sortByField == 'name' && !sortByAscOrder,
              }"
            >
              ▴
            </span>
          </th>
          <th
            class="select-none cursor-pointer"
            @click="onHeaderClick('email')"
          >
            Email
            <span
              class="arrow-sort-up"
              :class="{
                'opacity-100': sortByField == 'email' && sortByAscOrder,
              }"
            >
              ▴
            </span>
            <span
              class="arrow-sort-down"
              :class="{
                'opacity-100': sortByField == 'email' && !sortByAscOrder,
              }"
            >
              ▴
            </span>
          </th>
          <th
            class="select-none cursor-pointer"
            @click="onHeaderClick('state')"
          >
            Statut
            <span
              class="arrow-sort-up"
              :class="{
                'opacity-100': sortByField == 'state' && sortByAscOrder,
              }"
            >
              ▴
            </span>
            <span
              class="arrow-sort-down"
              :class="{
                'opacity-100': sortByField == 'state' && !sortByAscOrder,
              }"
            >
              ▴
            </span>
          </th>
          <th
            class="select-none cursor-pointer"
            @click="onHeaderClick('progressNum')"
          >
            Progression
            <span
              class="arrow-sort-up"
              :class="{
                'opacity-100': sortByField == 'progressNum' && sortByAscOrder,
              }"
            >
              ▴
            </span>
            <span
              class="arrow-sort-down"
              :class="{
                'opacity-100': sortByField == 'progressNum' && !sortByAscOrder,
              }"
            >
              ▴
            </span>
          </th>
          <th v-if="!isReadOnly"></th>
        </tr>

        <tr
          v-for="intern in promotionUserList"
          :key="intern.id"
          class="cursor-pointer"
          @click="onEditInternClick(intern)"
        >
          <template v-if="interns[intern.id]">
            <td>{{interns[intern.id].first_name}}</td>
            <td>{{interns[intern.id].name}}</td>
            <td>{{interns[intern.id].email}}</td>
            <td>{{interns[intern.id].state}}</td>
            <td>{{interns[intern.id].progress}}</td>
            <td
              v-if="!isReadOnly"
              class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
              @click.stop="onRemoveInternClick(intern)"
            >
              <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
            </td>
          </template>
        </tr>
      </table>
    </div>

    <UserImportModal v-model="isImportModalOpen" :defaultGroup="promotion.id" />
  </div>
</template>

<script>

import { mapState } from 'vuex'

import userFieldsValues from "@/constants/userFieldsValues"

import { getUserProgressionForGroup, getUserState, getUserGroupState } from "@/utils/userUtils"

import UserImportModal from '@/components/common/user/UserImportModal'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserImportModal
  },
  data() {
    return {
      isImportModalOpen: false,
      selectedUser: null,
      defaultCourse: null,
      sortByField: null,
      sortByAscOrder: true,
    }
  },
  async mounted() {
    this.defaultCourse = await this.$store.dispatch('Course/GetDefaultCourse')
  },
  computed: {
    ...mapState('Utils', ['userList']),
    ...mapState({
      courseList: state => state.Course.list,
    }),
    promotionUserList() {
      if (!this.sortByField) {
        return this.promotion.users
      }

      return this.promotion.users.concat([]).sort((u1, u2) => {
        if (u1[this.sortByField] < u2[this.sortByField]) {
          return (this.sortByAscOrder ? -1 : 1)
        }
        if (u1[this.sortByField] > u2[this.sortByField]) {
          return (this.sortByAscOrder ? 1 : -1)
        }
      
        return 0
      })
    },
    internsList() {
      if (!this.userList)
        return []

      return this.userList.filter((user) => {
        return !this.promotion.users.some((intern) => (user.id == intern.id))
      }).map((user) => {
        return {
          id: user.id,
          name: [user.first_name, user.name].filter(str => str || false).join(' ') + ' - ' + user.email,
        }
      })
    },
    interns() {
      if (!this.defaultCourse || !this.promotion.users || !this.courseList)
        return {}

      // Get real default course if one is set
      let defaultCourse = this.defaultCourse

      if (this.promotion.course) {
        defaultCourse = this.courseList.find((c) => c.id === this.promotion.course)
      }

      return this.promotion.users.reduce((dict, user) => {
        // Set user progress label
        const progression = getUserProgressionForGroup(user, this.promotion, defaultCourse)

        if (progression >= 1) {
          user.progressNum = 100
          user.progress = 'Parcours terminé'
        } else if (progression > 0) {
          const percentage = (progression * 100)

          if (percentage > 0 && percentage < 1) {
            return percentage.toFixed(2) + '%'
          }

          user.progressNum = Math.round(percentage)
          user.progress = Math.round(percentage) + '%'
        } else {
          user.progressNum = 0
          user.progress = '-'
        }

        // Set user state label
        user.state = userFieldsValues.stateMap[getUserState(user)]?.label || '-'

        // Add user group state label if needed
        const groupState = getUserGroupState(user)

        if (groupState === 'disabled') {
          user.state += ' - Promotion suspendue'
        } else if (groupState === 'inactive') {
          user.state += ' - Promotion inactive'
        }

        dict[user.id] = user

        return dict
      }, {})
    },
  },
  methods: {
    onHeaderClick(field) {
      // Sort user by this field
      if (this.sortByField != field) {
        this.sortByField = field
        this.sortByAscOrder = true
      } else if (this.sortByAscOrder) {
        this.sortByAscOrder = !this.sortByAscOrder
      } else {
        this.sortByField = null
        this.sortByAscOrder = true
      }
    },
    onUserSelect(intern) {
      this.$emit('add-intern', intern)

      this.$nextTick(() => this.selectedUser = null)
    },
    onEditInternClick(intern) {
      const routeData = this.$router.resolve({
        name: 'user-edit',
        params: {
          itemID: intern.id,
        },
      })
      window.open(routeData.href, '_blank');
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    },
    onImportUserClick() {
      this.isImportModalOpen = true
    }
  },
};
</script>

<style lang="scss">
  .arrow-sort-up {
    @apply inline-block opacity-50;
  }

  .arrow-sort-down {
    @apply inline-block opacity-50 transform rotate-180;
    padding-top: 0.1rem
  }

  .group-interns {
    @apply border-separate;

    tr {
      &:hover td {
        @apply border-gray-lighter;
      }
    }

    td {
      @apply py-1 border-t-1 border-b-1 border-white;
    }
  }
</style>