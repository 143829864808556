<template>
	<div class="narrative" v-if="narrative">
		<!-- Header -->
		<div class="narrative-header">
			<div class="buttons flex flex-row justify-between">
				<router-link class="twn-button text-xs" :to="{ name: 'narrative-list' }">
					Retour
				</router-link>

				<div class="buttons-right">
					<button
						@click="deleteNarrative"
						class="twn-button text-xs danger mr-4"
						type="button"
						:disabled="!itemID || hasPendingStoreRequest"
					>
						Supprimer
					</button>

					<button
						@click="duplicateNarrative"
						class="twn-button text-xs mr-4"
						type="button"
						:disabled="!itemID || hasPendingStoreRequest"
					>
						Dupliquer
					</button>

					<button
						v-if="canPreviewNarrative"
						@click="previewNarrative"
						:disabled="!itemID || hasPendingStoreRequest"
						class="twn-button text-xs mr-4"
						type="button"
					>
						Aperçu
					</button>

					<button
						@click="saveNarrative"
						class="twn-button text-xs"
						type="button"
						:disabled="hasPendingStoreRequest"
					>
						Enregistrer
					</button>
				</div>
			</div>
		</div>

		<!-- Narrative common fields -->
		<div class="pb-6 mb-10 mt-12 border-gray-light border-b-1">
			<!-- Identifier and title -->
			<div class="form-group inline-block w-1/3 pr-2">
				<label for="narrative-name" class="uppercase font-principal-medium text-sm">Identifiant</label>
				<b-form-input
					v-model="narrative.identifier"
					type="text"
					id="narrative-name"
					class="w-full"
				></b-form-input>
			</div>

			<!-- Name -->
			<div class="form-group inline-block w-2/3 pl-2">
				<label for="narrative-title" class="uppercase font-principal text-sm"
					>Titre</label
				>
				<b-form-input v-model="narrative.title" type="text" id="narrative-title" class="w-full"></b-form-input>
			</div>
		</div>

		<!-- Slides -->
		<div>
			<!-- List -->
			<div
				v-if="narrativeType && narrativeType.slug !== 'narrator' && narrativeType.slug !== 'legal_notice'"
				class="flex flex-no-wrap flex-row justify-around rounded border border-gray-light mb-8"
			>
				<Draggable
					class="flex-1 flex min-w-0 flex-no-wrap flex-row justify-around"
					v-model="slides"
					@change="onChangeSlides"
				>
					<div
						v-for="(slide, index) in slides"
						:key="slide.id || index"
						class="font-principal-medium flex-1 flex min-w-0 justify-center items-center cursor-pointer border-l px-4 py-2"
						:class="{
							'bg-white': (activeSlide != index),
							'bg-principal-selected text-white': (activeSlide == index),
							'border-none rounded-l': (index === 0),
						}"
						@click="activeSlide = index"
					>
						<b-icon-justify v-if="slides.length > 1" class="cursor-grab" />
						<p class="flex-grow text-center truncate px-2">
							{{ 'Slide ' + (slide.order + 1) + (slide.title ? ' - ' + slide.title : '') }}
						</p>
						<button
							v-if="slides.length > 1"
							class="text-red-700" 
							@click.stop="removeSlide(index)"
							>
							<b-icon-x />
						</button>
					</div>
				</Draggable>

				<button class="py-2 px-6 font-principal-bold text-xl border-l border-l-gray-light" @click="addSlide">+</button>
			</div>

			<!-- Content -->
			<Draggable v-if="narrativeType && narrativeType.slug === 'narrator'" v-model="slides" handle=".handle">
				<!-- Type specific slide editing component -->
				<component
					v-for="(slide, index) in slides"
					:key="slide.id || index"
					:is="slideComponent"
					:slide="slide"
					@update="updateSlide(index, $event)"
					@remove="removeSlide(index)"
				/>
			</Draggable>
			<component
				v-else-if="slides?.length > 0"
				:is="slideComponent"
				:slide="slides[activeSlide]"
				@update="updateSlide(activeSlide, $event)"
				@remove="removeSlide(activeSlide)"
				@set-blocks="setBlocks(activeSlide, $event)"
				@add-block="addBlock(activeSlide, $event)"
				@update-block="updateBlock(activeSlide, $event)"
				@delete-block="deleteBlock(activeSlide, $event)"
			/>

			<div
				v-if="narrativeType && narrativeType.slug === 'narrator'"
				@click="addSlide"
				class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl mt-6"
			>
				+
			</div>
		</div>

		<!-- Modals -->
		<b-modal
			ref="delete-modal-narrative"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-narrative"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Confirmer la suppression</h3>
			</div>

			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-narrative')">
					Retour
				</button>
				
				<button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

	import Draggable from 'vuedraggable'

	import NarratorSlide from "@/components/common/narrative/NarratorSlide"
	import BlockSlide from "@/components/common/narrative/BlockSlide"

	export default {
		name: 'NarrativeDetail',
		mixins: [ dispatchStoreRequest ],
		components: {
			Draggable
		},
		props: {
			itemID: {
				type: String,
				required: false,
				default: null
			}
		},
		data() {
			return {
				narrative: null,
				activeSlide: 0,
			}
		},
		computed: {
			...mapState('Narratives', {
				types: (state) => {
					if (!state.typeList || state.typeList.length <= 0)
						return {}

					return state.typeList.reduce((dict, type) => {
						dict[type.slug] = type

						return dict
					}, {})
				},
				slideTypes: (state) => {
					if (!state.slideTypeList || state.slideTypeList.length <= 0)
						return {}

					return state.slideTypeList.reduce((dict, type) => {
						dict[type.slug] = type

						return dict
					}, {})
				}
			}),
			narrativeType() {
				return this.$store.state.Narratives.typeList.find(type => (type.id == this.narrative.narrative_type_id))
			},
			slideComponent() {
				if (this.narrative && this.narrativeType) {
					switch (this.narrativeType.slug) {
						case 'narrator':
							return NarratorSlide

						case 'block':
							return BlockSlide

						case 'legal_notice':
							return BlockSlide
					}
				}

				return null
			},
			slides: {
				get() {
					if (!this.narrative)
						return []

					return [...this.narrative.slides]
				},
				set(slides) {
					// Make sure order field is correct
					slides.forEach((slide, index) => {
						slide.order = index
					})

					this.narrative.slides = slides
				}
			},
			canPreviewNarrative() {
				return !!process.env.VUE_APP_FRONT_URL
			},
		},
		watch: {
			itemID: {
				async handler(id) {
					// Load current narrative data, if needed
					if (id) {
						// Get narrative from store
						// todo: handle invalid uuid response
						await this.dispatchStoreRequest('Narratives/getByID', id, true)

						// Create a deep local copy of the store data
						this.narrative = JSON.parse(JSON.stringify(this.$store.state.Narratives.items[id]))

						// todo: common/cleaner system
						document.title = process.env.VUE_APP_PAGE_TITLE + ' - ' + [this.narrative.identifier, this.narrative.title].join(' - ')
					}
				},
				immediate: true
			},
			narrative: {
				handler(narrative) {
					// Automaticly add one slide if none provided
					if (narrative && narrative.slides && narrative.slides.length <= 0) {
						this.addSlide()
					}
				},
				immediate: true
			},
		},
		async created() {
			await this.dispatchStoreRequest('Narratives/getTypeList')
			await this.dispatchStoreRequest('Narratives/getSlideTypeList')
			await this.dispatchStoreRequest('Assets/getAssetList')

			if (!this.itemID) {
				// Create a new narrative of the default type
				this.narrative = {
					identifier: '',
					title: 'Nouvelle présentation',
					narrative_type_id: this.types['block'].id,
					slides: []
				}
			}
		},
		methods: {
			async duplicateNarrative(){

				this.narrative.title = this.narrative.title + ' (copie)'

				// Remove fields used for update format
				delete this.narrative.id

				this.narrative.slides.forEach(slide => {
					delete slide.id
					delete slide.narrative_id

					slide.metas.forEach(meta => {
						delete meta.id
						delete meta.slide_id
					})

					slide.blocks.forEach(block => {
						delete block.id
						delete block.slide_id

						block.metas.forEach(meta => {
							delete meta.id
							delete meta.slide_id
						})
					})
				})

				this.$router.push({
					name: 'narrative-edit'
				})
			},
			async saveNarrative() {
				const response = await this.dispatchStoreRequest("Narratives/save", this.narrative)

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })

				if (!this.itemID && response.id) {
					this.$router.push({
						name: 'narrative-edit',
						params: {
							itemID: response.id
						}
					})
				}
			},
			deleteNarrative() {
				if (this.itemID) {
					this.$refs["delete-modal-narrative"].show()
				}
			},
			async confirmDelete() {
				await this.dispatchStoreRequest("Narratives/delete", this.narrative.id)

				this.$router.push({ name: 'narrative-list' })
			},
			addSlide() {
				this.narrative.slides.push({
					title: 'Nouveau slide',
					text: '',
					order: this.narrative.slides.length,
					slide_type_id: this.slideTypes['block'].id,
					metas: [],
					blocks: [],
				})

				this.activeSlide = (this.narrative.slides.length - 1)
			},
			updateSlide(index, slideData) {
				const dataKeys = Object.keys(slideData)

				for (var i = 0; i < dataKeys.length; i++) {
					this.narrative.slides[index][dataKeys[i]] = slideData[dataKeys[i]]
				}
			},
			removeSlide(index) {
				if (this.narrative.slides.length <= 1)
					return

				// Remove slide
				this.narrative.slides.splice(index, 1)
				
				// Make sure order field is correct
				this.narrative.slides.forEach((slide, i) => {
					slide.order = i
				})

				// Update active slide if needed
				if (this.activeSlide > 0 && this.activeSlide > index) {
					// Make sure new active slide index is valid
					this.activeSlide = Math.max(Math.min(this.activeSlide - 1, this.slides.length - 1), 0)
				}
			},
			onChangeSlides({ moved }) {
				if (!moved) {
					return
				}

				const { newIndex, oldIndex } = moved

				if (this.activeSlide === oldIndex) {
					this.activeSlide = newIndex
				}
			},
			setBlocks(slideIndex, blocks) {
				// Make sure order field is correct
				blocks.forEach((block, index) => {
					block.order = index
				})

				this.$set(this.narrative.slides[slideIndex], 'blocks', blocks)
			},
			addBlock(slideIndex, type = 'text') {
				this.narrative.slides[slideIndex].blocks.push({
					title: null,
					text: null,
					order: this.narrative.slides[slideIndex].blocks.length,
					slide_block_type_slug: type,
					metas: [],
					media: [],
				})
			},
			updateBlock(slideIndex, blockData) {
				const blockIndex = blockData.index
				const blockFields = blockData.fields
				
				const fieldNames = Object.keys(blockFields)
				const block = this.narrative.slides[slideIndex].blocks[blockIndex]

				for (var i = 0; i < fieldNames.length; i++) {
					this.$set(block, fieldNames[i], blockFields[fieldNames[i]])
				}
			},
			deleteBlock(slideIndex, blockIndex) {
				if (this.narrative.slides[slideIndex].blocks.length <= 0)
					return

				// Remove slide
				this.narrative.slides[slideIndex].blocks.splice(blockIndex, 1)
				
				// Make sure order field is correct
				this.narrative.slides[slideIndex].blocks.forEach((block, i) => {
					block.order = i
				})
			},
			previewNarrative() {
				this.$store.dispatch('Utils/openFrontURL', 'narrative/' + this.itemID)
			},
		}
	}
</script>

<style lang="scss" scoped></style>