function mailDateFromLog(user, mailType) {
	if (!user.mailingLogs[mailType]) {
		return null
	}

	return new Date(user.mailingLogs[mailType].created_at)
}

const typeNameBySlugs = {
	'quiz_simple': 'QCM',
	'true_false': 'VF',
	'gap_fill': 'Texte à trous',
	'reorder': 'Réordonner',
	'dragdrop': 'Associé texte',
	'dragdrop_picture': 'Associé image',
}

export default {
	name: 'Utilisateurs',
	content: [
		{ 
			cells(data) {
				// Add headers for each potential module order
				const modulesHeaders = []
				const maxNumberOfModules = Object.keys(data.coursesData.potentialTypesByModuleOrder).length

				for (var i = 0; i < maxNumberOfModules; i++) {
					const typeCount = Object.keys(data.coursesData.potentialTypesByModuleOrder[i]).length

					// Add a column for each potential activity type (or at least one column if none)
					modulesHeaders.push(`Module ${i + 1}`)
					
					for (var j = 0; j < typeCount - 1; j++) {
						modulesHeaders.push('')
					}
				}

				return [
					'PROMOTION',
					'',
					'UTILISATEUR',
					'',
					'',
					'',
					'',
					'',
					'',
				].concat(modulesHeaders)
			},
		},
		{
			table: 'users',
			headers(_, data) {
				// Add a header for each potential module order
				const modulesHeaders = []
				const maxNumberOfModules = Object.keys(data.coursesData.potentialTypesByModuleOrder).length

				for (var i = 0; i < maxNumberOfModules; i++) {
					const potentialTypeSlugs = Object.keys(data.coursesData.potentialTypesByModuleOrder[i])

					// Add a column for each potential activity type (or at least one column if none)
					if (!potentialTypeSlugs.length) {
						modulesHeaders.push('')
					} else {
						potentialTypeSlugs.forEach((slug) => {
							modulesHeaders.push(typeNameBySlugs[slug] || slug)
						})
					}
				}

				return [
					'ID Promotion',
					'Promotion',
					'Nom',
					'Prénom',
					'Date d\'invitation',
					'Dernière connexion',
					'Nombre de connexion',
					'Durée totale de connexion',
					'Progression'
				].concat(modulesHeaders)
			},
			rows: (user, data) => {
				if (!user.groups?.length) {
					return []
				}

				return user.groups.filter((groupData) => {
					const group = (groupData.id && data.groupsById[groupData.id])

					if (!group) {
						return false
					}

					const course = (group.course.id && data.coursesById[group.course.id])

					if (!course) {
						return false
					}
					
					return true
				}).map((groupData) => {
					const group = data.groupsById[groupData.id]
					const course = data.coursesById[group.course.id]

					const accessDate = (mailDateFromLog(user, 'access-account') || mailDateFromLog(user, 'access-before') || mailDateFromLog(user, 'access-late') || mailDateFromLog(user, 'access-active'))

					// Get user modules activities data for this group (number of activities succesfully done per type)
					const modulesData = course.modules.reduce((list, module) => {
						const moduleData = course.modulesActivitiesData[module.id]
						const userData = user.modulesActivitiesData[module.id] || {}

						const potentialTypeSlugs = Object.keys(data.coursesData.potentialTypesByModuleOrder[module.order])

						if (!potentialTypeSlugs.length) {
							list.push({})
						} else {
							list = list.concat(
								potentialTypeSlugs.map((slug) => {
									if (!moduleData[slug]) {
										return {}
									}

									return { value: `${userData[slug] || 0}/${moduleData[slug]}` }
								})
							)
						}

						return list
					}, [])

					return [
						{ value: (group?.identifier || '') },
						{ value: (group?.name || '') },
						'last_name',
						'first_name',
						{ value: (accessDate?.getTime() || '-'), type: (accessDate ? 'date' : undefined) },
						{ value: user.activeTimeData.lastSessionTimestamp, type: 'date' },
						{ value: user.activeTimeData.times.length },
						{ value: user.activeTimeData.sum, type: 'duration' },
						{ value: user.progressionData.progress || 0, type: 'percentage' },
					].concat(modulesData)
				})
			},
		}
	]
}